import { Injectable, signal } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class SuccessHandlerService {
  successMessage = signal<string | null>(null);
  counter = signal<number>(100);
  private intervalId: any;

  handleSuccess(message: string) {
    this.successMessage.set(message);
    this.startCountDown();
  }

  clearSuccess() {
    this.successMessage.set(null);
  }

  private startCountDown() {
    if (this.intervalId) {
      clearInterval(this.intervalId);
    }

    this.counter.set(100);
    this.intervalId = setInterval(() => {
      const currentValue = this.counter();
      if (currentValue > 0) {
        this.counter.set(currentValue - 1);
      } else {
        this.clearSuccess();
        clearInterval(this.intervalId);
      }
    }, 40);
  }
}
